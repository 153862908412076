import React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "./layout";
import Formular from "./Formular";
import Button from "./Button";
import Seo from "./Seo";

const components = { Formular, Button };

export default function DefaultTemplate({ data: { mdx } }) {
  return (
    <Layout>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        url={mdx.frontmatter.path}
      />
      <section id="inhalt">
        <div className="section-wrapper">
          <MDXProvider components={components}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query PAGE_QUERY($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        path
        description
      }
    }
  }
`;
