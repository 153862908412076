import React from "react";
import { Link } from "gatsby";

export default function Button({ title, link }) {
  return (
    <Link
      to={link || "/kontakt#kontaktform"}
      className="btn"
      title={title || "Angebot anfragen"}
    >
      <span className="btn-text">{title || "Angebot anfragen"}</span>
    </Link>
  );
}
